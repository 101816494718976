import React from "react";
import PostalLookupInput from "../components/PostalLookupInput";

export default class Search extends React.Component {
  render() {
    return (
      <section className="search">
        <div className="search__inner">
          <header>
            <h2 className="title title--secondary">Get to Know Canadians by Neighbourhood</h2>
            <p className="title title--subtitle">
              Enter a postal code to see sample PRIZM<sup>®</sup> profiles, including demographic, behavioural, financial, health and psychographic insights.
            </p>
          </header>
          <PostalLookupInput
            handlePostalLookup={() => this.props.handlePostalLookup("Main Lookup")}
            currentPostalCode={this.props.currentPostalCode}
            multiSegments={this.props.multiSegments}
            setMultiSegment={this.props.setMultiSegment}
            handleChange={this.props.handleChange}
            postalLoading={this.props.postalLoading}
          />
          <p className="search__error">{this.props.errorMessage}</p>
        </div>
      </section>
    );
  }
}
