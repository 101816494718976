import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TagManager from "react-gtm-module";
import ReactMarkdown from "react-markdown";

export default class SegmentDetailsSlide extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      truncated: true,
    };
  }

  renderTruncatedText(str) {
    return str.split(" ").splice(0, 75).join(" ");
  }

  showTruncatedText() {
    this.setState({ truncated: false });
  }

  isShowing = (index) => {
    var location = this.props.segments.findIndex((segment) => segment.id === this.props.currentSegment);

    if (index === location || index === location - 1 || index === location + 1) {
      return true;
    }
  };

  handleClick(id) {
    TagManager.dataLayer({
      dataLayer: {
        type: "event",
        event: "Interaction",
        event_category: "Carousel",
        event_type: "Change Active Tab",
        event_detail: id.toString(),
      },
    });
  }

  renderSlides() {
    let slides = [...this.props.segments];
    const currentSegmentIndex = slides.findIndex((segment) => segment.id === this.props.currentSegment);

    if (currentSegmentIndex === 0 && slides.length > 1) {
      // The first item
      const slidesTotal = slides.length;
      const lastItem = slides[slidesTotal - 1];
      slides.unshift(lastItem);
      slides = slides.slice(0, 3);
    } else if (currentSegmentIndex === slides.length - 1 && slides.length > 1) {
      // The last item
      if (slides.length >= 3) {
        slides.push(slides.shift());
        slides = slides.slice(currentSegmentIndex - 2, currentSegmentIndex + 1);
      } else if (slides.length === 2) {
        const firstItem = slides[0];
        slides.push(firstItem);
      }
    } else {
      // Everything else
      slides = slides.slice(currentSegmentIndex - 1, currentSegmentIndex + 2);
    }

    return slides.map((segment, index) => (
      <li key={segment["name"] + index} className={this.props.currentSegment === segment["id"] ? "segment-details__slide segment-details__slide--active" : "segment-details__slide"}>
        <header>
          {this.props.searchedSegment === segment["id"] ? <div className="segment-details__represents">{this.props.currentPostalCode} represents</div> : null}
          {this.props.ruralSegment === segment["id"] ? <div className="segment-details__represents">{this.props.ruralName} represents</div> : null}
          <div className="segment-details__title">
            {segment["featured"] ? (
              <div className="segment-details__badge">                
                <div className="segment-details__badge__label">
                  Ideal<br/>Segment
                </div>
                <div className="segment-details__badge__icon">
                  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 7.23711C2.66105 6.97938 4.76472 6.73196 6.86839 6.49485C7.09789 6.46392 7.23176 6.38144 7.33694 6.13402C8.16885 4.19588 9.01032 2.25773 9.84222 0.319588C9.88047 0.226804 9.92828 0.134021 9.98566 0C10.043 0.113402 10.0908 0.195876 10.1291 0.278351C10.9801 2.24742 11.8312 4.21649 12.6726 6.19588C12.7491 6.38144 12.8543 6.46392 13.036 6.48454C14.1165 6.60825 15.197 6.73196 16.2871 6.86598C17.339 6.98969 18.3812 7.1134 19.4331 7.23711C19.4522 7.26804 19.4809 7.29897 19.5 7.3299C19.4331 7.37113 19.347 7.39175 19.2992 7.4433C17.7597 8.91753 16.2202 10.4021 14.6807 11.8763C14.5468 12 14.5181 12.1134 14.5564 12.299C14.9771 14.4433 15.3978 16.5876 15.8186 18.7216C15.8281 18.7938 15.8377 18.866 15.8473 18.9794C15.7516 18.9278 15.6751 18.8866 15.5986 18.8454C13.8105 17.7732 12.032 16.7113 10.2438 15.6392C10.0526 15.5258 9.90916 15.5361 9.72748 15.6495C7.94892 16.7216 6.17036 17.7835 4.3918 18.8454C4.3153 18.8866 4.2388 18.9278 4.12406 19C4.22924 18.433 4.32486 17.9278 4.42048 17.4227C4.75516 15.6907 5.0994 13.9588 5.44363 12.2268C5.47232 12.0619 5.41495 11.9794 5.30976 11.8763C3.77026 10.4021 2.23075 8.91753 0.700805 7.4433C0.643432 7.39175 0.595622 7.3299 0.5 7.23711Z" fill="#FAD141"/>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="segment-details__number">{("0" + segment["id"]).slice(-2)}</div>
            )}
            <h2 className="title title--secondary">{segment["name"]}</h2>
          </div>
          <p className="segment-details__short-description">{segment["short_description"]}</p>
          <p>
            This segment represents <em>{segment["percent_of_population"]}% of Canada's population</em> and <em>{segment["percent_of_households"]}% of households.</em>
          </p>
        </header>
        <Tabs>
          <TabList>
            <Tab tabIndex={segment["id"] === this.props.currentSegment ? "0" : "-1"} onClick={() => this.handleClick(segment["id"])}>
              Who They Are
            </Tab>
            <Tab tabIndex={segment["id"] === this.props.currentSegment ? "0" : "-1"} onClick={() => this.handleClick(segment["id"])}>
              How They Think
            </Tab>
            <Tab tabIndex={segment["id"] === this.props.currentSegment ? "0" : "-1"} onClick={() => this.handleClick(segment["id"])}>
              How They Live
            </Tab>
          </TabList>

          <TabPanel>
            <section className="segment-details__slide__who">
              <div className="segment-details__slide__who__text">
                <div className={this.state.truncated ? "util__mobile-only" : "util__mobile-only util__hidden"}>
                  <ReactMarkdown>{this.renderTruncatedText(segment["description"])}</ReactMarkdown> <button onClick={(event) => this.showTruncatedText(event)}>Read More</button>
                </div>
                <div className={this.state.truncated ? "util__mobile-only util__hidden" : "util__mobile-only"}>
                  <ReactMarkdown>{segment["description"]}</ReactMarkdown>
                </div>
                <div className="util__desktop-only">
                  <ReactMarkdown>{segment["description"]}</ReactMarkdown>
                </div>
              </div>
              <ul>
                {segment["who_they_are"].map((item, index) => {
                  return (
                    <li key={`point${index}`}>
                      <div className="react-tabs__tab-item__title">{item.title}</div>
                      <ReactMarkdown>{item.value.replace(/\//g, " / ")}</ReactMarkdown>
                    </li>
                  );
                })}
              </ul>
            </section>
          </TabPanel>
          <TabPanel>
            <section className="segment-details__slide__think">
              <ul>
                {segment["how_they_think"].map((item, index) => {
                  return <li key={`thinkpoint${index}`}>{item}</li>;
                })}
              </ul>
            </section>
          </TabPanel>
          <TabPanel>
            <section className="segment-details__slide__live">
              <ul>
                {segment["how_they_live"].map((item, index) => {
                  return (
                    <li key={`point${index}`}>
                      <img src={`/assets/img/icons/${item.slug}.svg`} alt={item.title} />
                      <h6>{item.title}</h6>
                      <ReactMarkdown>{item.value.replace(/\//g, " / ")}</ReactMarkdown>
                    </li>
                  );
                })}
              </ul>
            </section>
          </TabPanel>
        </Tabs>
      </li>
    ));
  }

  render() {
    return this.renderSlides();
  }
}
