import React from "react";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.state = {
      username: null,
      password: null,
    };
  }

  componentDidMount() {
    this.modal.current.focus();
  }

  keyPress = (event) => {
    if (event.keyCode === 13) {
      this.props.handleLogin(this.state);
    }
  };

  render() {
    return (
      <section className="modal">
        <div className="modal__inner modal__inner--login" ref={this.modal} tabIndex="0">
          <h3 className="title">Login</h3>
          <p>For Edward Jones employees only</p>
          <div className="field">
            <label htmlFor="login__username">Username</label>
            <input id="login__username" type="text" onKeyDown={(event) => this.keyPress(event)} onChange={(event) => this.setState({ username: event.target.value })} />
          </div>
          <div className="field">
            <label htmlFor="login__password">Password</label>
            <input id="login__password" type="password" onKeyDown={(event) => this.keyPress(event)} onChange={(event) => this.setState({ password: event.target.value })} />
          </div>
          <button className="primary-cta" onClick={() => this.props.handleLogin(this.state)}>
            Login
          </button>
          {this.props.message && <p className="modal__inner__error">{this.props.message}</p>}
          <p>
            <a href="mailto:marketing@environicsanalytics.com?subject=Password%20Reset%20Inquiry%20via%20Edward%20Jones%20PRIZM%20Tool">
              Forgot your password? Contact us to reset.
            </a>
          </p>
        </div>
      </section>
    );
  }
}
