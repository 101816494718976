import React from "react";
import Loader from "../components/Loader";
import PostalLookupInputField from "../components/PostalLookupInputField";

export default class PostalLookupInput extends React.Component {
  keyPress = (event) => {
    if (event.keyCode === 13) {
      this.props.getPostalData();
    }
  };

  renderMulti = () => {
    let segments = [...this.props.multiSegments];

    if (segments.length > 1) {
      return segments.map((segment, index) => (
        <li key={index}>
          <button onClick={() => this.props.setMultiSegment(segment.prizm_id, segment.title)}>{segment.title}</button>
        </li>
      ));
    }
  };

  handlePostalFormSubmission = (event) => {
    event.preventDefault();
    this.props.handlePostalLookup();
  };

  render() {
    return (
      <div className="postal-lookup">
        <form className="postal-lookup__input" onSubmit={(event) => this.handlePostalFormSubmission(event)}>
          <PostalLookupInputField postalCode={this.props.currentPostalCode} handleChange={this.props.handleChange} />
          {this.props.postalLoading ? <Loader /> : null}
          <button className="primary-cta">GO</button>
        </form>
        <div className={this.props.multiSegments.length > 1 ? "postal-lookup__multi" : "postal-lookup__multi util__hidden"}>
          <header>
            {this.props.currentPostalCode} is a rural postal code associated with multiple communities. Select a community to see its PRIZM® segment assignment. <br />
            <br />
            The map will display the geographic point most commonly associated with this postal code.
          </header>
          <div className="postal-lookup__multi__inner">
            <ul>{this.renderMulti()}</ul>
          </div>
        </div>
      </div>
    );
  }
}
