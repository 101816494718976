import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <svg className="loader__circle" viewBox="25 25 50 50">
          <circle className="loader__circle__path" cx="50" cy="50" r="20" fill="none" stroke="#0090C2" strokeWidth="2" />
        </svg>
      </div>
    );
  }
}

export default Loader;
