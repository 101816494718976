import React from "react";
import FocusLock from "react-focus-lock";

export default class ModalContactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current.focus();
  }

  render() {
    return (
      <section className="modal">
        <FocusLock returnFocus>
          <div className={`modal__inner modal__inner--contact`} ref={this.modal} tabIndex="0">
            <h3 className="title title--secondary">Contact Us</h3>
            <p>Contact us via one of the following options</p>
            <div className="buttons-group">
              <a className="primary-cta" href="mailto:marketing@environicsanalytics.com?subject=Technical%20Support%20Inquiry%20via%20Edward%20Jones%20PRIZM%20Tool">
                Technical Support 
              </a>
              <a className="primary-cta" href="mailto:branch_marketing_canada@edwardjones.com?subject=Marketing%20Toolkit%20and%20Strategy%20Inquiry%20via%20PRIZM%20Tool">
                Marketing Toolkit & Strategy Inquiries
              </a>
            </div>
            <button className="modal__inner__close" onClick={() => this.props.closeContactDetails()} aria-label="Close Modal"></button>
          </div>
        </FocusLock>
      </section>
    );
  }
}
