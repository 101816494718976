(function() {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
    }
  }

  function handleClick() {
    document.body.classList.remove("user-is-tabbing");
  }

  document.addEventListener("keydown", handleFirstTab);
  document.addEventListener("click", handleClick);
})();
