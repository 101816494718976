import React from "react";

export default class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      income: false,
      family: false,
      segment: false,
    };
  }

  componentWillUnmount() {
    this.clearFilters();
    this.props.clearFilters();
  }

  renderFamilyLifeItems = () => {
    let results = [],
      ordering = {},
      sortOrder = [
        // custom sort ordering
        "Very Young Singles & Couples",
        "Younger Singles & Couples",
        "Young Families",
        "School-Age Families",
        "Large Diverse Families",
        "Middle-Age Families",
        "Older Families & Empty Nests",
        "Mature Singles & Couples",
      ];

    // Map the ordering to an object
    for (var i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    this.props.segments.forEach((segment) => {
      let item = segment["filters"]["family_life"];
      if (!results.includes(item)) {
        results.push(item);
      }
    });

    // sort the results
    results.sort(function (a, b) {
      return ordering[a] - ordering[b] || a.localeCompare(b);
    });

    return results.map((result, index) => (
      <option key={index} value={result}>
        {result}
      </option>
    ));
  };

  resetDropdown = (input, filter) => {
    input.value = "All";
    this.setState({ [filter]: false }, () => {
      this.sendFilters();
    });
  };

  handleFilterChange = (value, filter) => {
    // Add conditional here to determine if we want to show the clear filters button
    value !== "All" ? this.setState({ [filter]: true }) : this.setState({ [filter]: false });
    // Update the activeFilters object in the main app state
    this.sendFilters();
  };

  sendFilters() {
    let filters = {
      income: this.filtersIncomeInput.value,
      family: this.filtersFamilyInput.value,
      segment: this.filtersSegmentInput.value,
    };
    this.props.updateFilters(filters);
  }

  clearFilters() {
    this.resetDropdown(this.filtersFamilyInput, "family");
    this.resetDropdown(this.filtersIncomeInput, "income");
    this.resetDropdown(this.filtersSegmentInput, "segment");
  }

  render() {
    return (
      <section className="filter-bar">
        <ul>
          <li>
            <label htmlFor="filterIncome">Household Income:</label>
            <div className="select-group">
              <select id="filterIncome" onChange={(event) => this.handleFilterChange(event.target.value, "income")} ref={(el) => (this.filtersIncomeInput = el)}>
                <option value="All">All</option>
                <option value="<75000">Under $75,000</option>
                <option value="~75000|100000">$75,000 to $100,000</option>
                <option value="~100000|125000">$100,000 to $125,000</option>
                <option value="~125000|150000">$125,000 to $150,000</option>
                <option value=">150000">Over $150,000</option>
              </select>
              <button
                className={this.state.income ? "select-group__clear select-group__clear--active" : "select-group__clear"}
                onClick={() => this.resetDropdown(this.filtersIncomeInput, "income")}
                aria-label="Remove Filter"
              />
            </div>
          </li>
          <li>
            <label htmlFor="filterFamily">Family Life:</label>
            <div className="select-group">
              <select id="filterFamily" onChange={(event) => this.handleFilterChange(event.target.value, "family")} ref={(el) => (this.filtersFamilyInput = el)}>
                <option value="All">All</option>
                {this.renderFamilyLifeItems()}
              </select>
              <button
                className={this.state.family ? "select-group__clear select-group__clear--active" : "select-group__clear"}
                onClick={() => this.resetDropdown(this.filtersFamilyInput, "family")}
                aria-label="Remove Filter"
              />
            </div>
          </li>
          <li>
            <label htmlFor="filterIncome">Segment Type:</label>
            <div className="select-group">
              <select id="filterIncome" onChange={(event) => this.handleFilterChange(event.target.value, "segment")} ref={(el) => (this.filtersSegmentInput = el)}>
                <option value="All">All</option>
                <option value="Ideal">Ideal Client Segments</option>
                <option value="Other">Rest of Canada</option>
              </select>
              <button
                className={this.state.segment ? "select-group__clear select-group__clear--active" : "select-group__clear"}
                onClick={() => this.resetDropdown(this.filtersSegmentInput, "segment")}
                aria-label="Remove Filter"
              />
            </div>
          </li>
        </ul>
        {this.props.hasResults && (
          <section className="filter-bar__message" role="alert">
            <p>No segments match your filter criteria</p>
            <button onClick={() => this.clearFilters()}>Clear Filters</button>
          </section>
        )}
      </section>
    );
  }
}
