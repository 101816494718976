import React, { Suspense } from "react";
import PostalLookupInput from "../components/PostalLookupInput";
import Loader from "../components/Loader";
const HeroMap = React.lazy(() => import("../components/HeroMap"));

export default class Hero extends React.PureComponent {
  render() {
    return (
      <section className="hero" id="main">
        <div className="hero__inner">
          <div className="hero__intro">
            <h1 className="title title--main">
              See Canada Through a Whole New PRIZM<sup>®</sup>
            </h1>
            <h2 className="title title--secondary title--secondary--font-size-large">With Powerful Consumer Insights</h2>
            <p className="title title--subtitle title--subtitle title--subtitle--font-size-large">Discover which of the 67 unique lifestyle types make up a neighbourhood</p>
            <PostalLookupInput
              handlePostalLookup={() => this.props.handlePostalLookup("Hero Lookup")}
              multiSegments={this.props.multiSegments}
              setMultiSegment={this.props.setMultiSegment}
              currentPostalCode={this.props.currentPostalCode}
              handleChange={this.props.handleChange}
              postalLoading={this.props.postalLoading}
            />
            <p className={this.props.errorMessage ? "hero__intro__error hero__intro__error--active" : "hero__intro__error"} role="alert">
              {this.props.errorMessage}
            </p>
          </div>
          <Suspense fallback={<Loader />}>
            <HeroMap segments={this.props.segments} onClick={this.props.onClick} />
          </Suspense>
          <a href="#explore" className="hero__scroll-to util__desktop-only">
            Explore Segments
          </a>
        </div>
      </section>
    );
  }
}
