import React from "react";

export default class TOS extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current.focus();
  }

  render() {
    return (
      <section className="modal">
        <div className={`modal__inner modal__inner--tos`} ref={this.modal} tabIndex="0">
          <h3 className="title title--secondary">Terms Of Service</h3>
          <p>This PRIZM® Lookup Application tool is intended to be used as a demonstration of the <a href="https://environicsanalytics.com/en-ca/data/segmentation" target="_blank" rel="noopener noreferrer">PRIZM segmentation system</a> and for marketing purposes only.</p>
          <p><a href="https://environicsanalytics.com/en-ca" taget="_blank" rel="noopener noreferrer">Environics Analytics</a> does not recommend basing any business, planning or marketing decisions on the results of this postal code lookup, a proprietary product of Environics Analytics (‘EA’). It is not intended as a research tool and should not be used as such.</p>
          <p>By using this tool, you agree not to by any means monetize, trace, reverse engineer or disseminate the data contained herein in any way, whether in whole or in part for any purpose, commercial or otherwise.</p>
          <p>By using the PRIZM® Lookup Application tool, you are expressing consent to be bound by the terms of this site.</p>
          <button className="modal__inner__close" onClick={() => this.props.closeTOS()} aria-label="Close Modal"></button>
        </div>
      </section>
    );
  }
}
