import React, { useRef, useEffect, memo } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import TagManager from "react-gtm-module";

function SegmentThumbnails({ segments, currentSegment, onClick }) {
  const activeSegment = useRef(null);
  const scrollContainer = useRef(null);

  useEffect(() => {
    const handleResize = () => centerSegments();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (activeSegment.current) {
      centerSegments();
    }
  }, [segments, currentSegment]);

  const centerSegments = () => {
    let offsetLeft = activeSegment.current.offsetLeft + activeSegment.current.clientWidth / 2 - document.documentElement.clientWidth / 2;
    scrollContainer.current.scroll({ left: offsetLeft, behavior: "smooth" });
  };

  const onClickSegment = (id) => {
    TagManager.dataLayer({
      dataLayer: {
        type: "event",
        event: "Interaction",
        event_category: "Carousel",
        event_type: "Change Segment",
        event_detail: id.toString(),
      },
    });
    onClick(id);
  };

  const renderSegments = () => {
    return segments.map((segment) => (
      <button
        role="listitem"
        className={currentSegment === segment["id"] ? "segment-thumbnails__nav__item segment-thumbnails__nav__item--active" : "segment-thumbnails__nav__item"}
        ref={currentSegment === segment["id"] ? activeSegment : null}
        onClick={() => onClickSegment(segment["id"])}
        key={segment["id"]}
      >
        <div className="segment-thumbnails__nav__item__inner">
          {segment["featured"] && (
            <div className="segment-thumbnails__nav__item__badge">Ideal Segment</div>
          )}
          <img src={`/assets/img/segment_icons/segment_${segment["id"]}.svg`} alt="" loading="lazy" />
          <div className="segment-thumbnails__nav__item__content">
            <span>{("0" + segment["id"]).slice(-2)}</span>
            <div className="segment-thumbnails__nav__item__content__title">{segment["name"]}</div>
          </div>
        </div>
      </button>
    ));
  };

  return (
    <ScrollContainer className="segment-thumbnails__container" innerRef={scrollContainer}>
      <nav className="segment-thumbnails__nav" role="list">
        {renderSegments()}
      </nav>
    </ScrollContainer>
  );
}

export default memo(SegmentThumbnails);
