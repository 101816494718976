import React from "react";
import Loader from "../components/Loader";

export default class FilterStats extends React.Component {
  calculateHouseholds = () => {
    var total = 0;
    this.props.segments.forEach((segment) => {
      total += segment["households"];
    });
    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  calculatePostalCodes = () => {
    var total = this.props.filteredPostalCount;
    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    return (
      <section className="filter-stats">
        <ul>
          <li>
            <b>{this.props.segments.length}</b> Segments
          </li>
          <li>
            <b>{this.calculateHouseholds()}</b> Households
          </li>
          <li>
            <b>{this.props.filteredPostalCount ? this.calculatePostalCodes() : <Loader />}</b>
            Postal Codes
          </li>
          <li>
            <button className={this.props.filtersActive ? "button--secondary button--secondary--active" : "button--secondary"} onClick={() => this.props.onClick()}>
              <i className="filter-stats__icon"></i> Filter Segments
            </button>
          </li>
        </ul>
      </section>
    );
  }
}
