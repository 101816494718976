import React from "react";

const banner = {
  text: "We use cookies to personalize content and ads, to ensure you have a good experience and to better understand how you browse our website. To learn more about cookies, see our ",
  link: {
    href: "https://environicsanalytics.com/footer/privacy",
    text: "Privacy Policy",
  },
};

export default class GDPRBanner extends React.Component {
  render() {
    return (
      <section className="gdpr-banner">
        <div className="gdpr-banner__message">
          <p>
            {banner.text}
            <a href={banner.link.href}>{banner.link.text}</a>
          </p>
        </div>
        <div className="gdpr-banner__buttons">
          <button className="gdpr-banner__buttons__accept primary-cta" onClick={() => this.props.setGDPRCookie(true)}>
            Accept
          </button>
          <button className="gdpr-banner__buttons__decline" onClick={() => this.props.setGDPRCookie(false)}>
            Decline
          </button>
        </div>
      </section>
    );
  }
}
